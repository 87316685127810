.weekly-ratings-delta {
  font-size: 1rem;
}

.change-number {
  font-size: 1.5rem;
}

.change-positive {
  color: green;
}

.change-negative {
  color: rgb(255, 99, 132);
}

.card-active {
  border-left: 5px solid rgb(255, 99, 132);
}

.card-everyday {
  border-left: 5px solid rgb(0, 235, 100);
}

.card-smart {
  border-left: 5px solid rgb(53, 162, 235);
}

.card-sodamaker {
  border-left: 5px solid rgb(120, 110, 170);
}
