.btn-arrow {
  /* width: 50px; */
  /* height: 30px; */
  padding: 2px;
}

.complaint-count {
  font-size: 1rem;
  font-weight: bold;
}

.material-symbols-outlined {
  vertical-align: middle;
  pointer-events: none;
}
